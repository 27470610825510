import React, { Component } from "react";
import Projects from "../components/Projects";
import Helmet from "react-helmet";

class ProjectsPage extends Component {
  render() {
    return (
      <div>
        <Helmet
          title="Projects | Enlight"
          meta={[
            {
              name: "description",
              content:
                "Discover your next Enlight project in Web Development, Data Science, and more."
            },
            {
              name: "og:title",
              content: "Projects | Enlight"
            },
            {
              name: "og:description",
              content:
                "Discover your next Enlight project in Web Development, Data Science, and more."
            },
            {
              name: "og:url",
              content: "https://enlight.nyc/projects"
            },
            {
              name: "og:image",
              content:
                "https://enlight.nyc/img/courses/web-development/projects.png"
            },
            {
              name: "twitter:title",
              content: "Projects | Enlight"
            },
            {
              name: "twitter:image",
              content:
                "https://enlight.nyc/img/courses/web-development/projects.png"
            }
          ]}
        />
        <Projects />
      </div>
    );
  }
}

export default ProjectsPage;
